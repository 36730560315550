<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>培训学员列表</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">培训学员列表</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <div class="wrap">
      <el-table :data="List" border height="620">
        <el-table-column label="学员头像" width="100">
          <template slot-scope="scope">
            <el-avatar :size="30" :src="scope.row.userHead"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="学员名称">
        </el-table-column>
        <el-table-column label="签到课程">
          <template slot-scope="scope">
            <div v-for="(item,i) in scope.row.signInCourseNameList" :key="i">{{item}}</div>
          </template>
        </el-table-column>
        <el-table-column label="课后测评">
          <template slot-scope="scope">
            <div v-for="(item1,i) in scope.row.evaluateResultVOList" :key="i">
              <div><span>{{item1.evaluateTitle}}</span>结果：<span>{{item1.result}}</span></div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
  
  
  
  <script>
export default {
  data() {
    return {
      id: '',
      // 列表
      List: [],
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getList()
  },
  methods: {
    // 列表
    async getList() {
      const { data: res } = await this.$http.get('/admin/trainingUser/getTrainingUserInfoList', {
        params: {
          trainingId: this.id,
        }
      })
      this.List = res.data
    },
  }
}
  </script>
  <style scoped lang="scss"></style>