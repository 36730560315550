<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/sysUser_list' }">系统用户列表</el-breadcrumb-item>
            <el-breadcrumb-item>系统用户编辑</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">编辑系统用户信息</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <div class="formWidth">
        <el-form :model="form" :rules="rules" ref="form" :label-position="labelPosition" :hide-required-asterisk="true">
          <div class="grid-content">
            <el-form-item label="用户名" :label-width="formLabelWidth" prop="sysUserName">
              <el-input v-model="form.sysUserName" autocomplete="off" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="用户密码" :label-width="formLabelWidth" prop="sysUserPassword">
              <el-input v-model="form.sysUserPassword" autocomplete="off" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="选择角色" :label-width="formLabelWidth" prop="sysUserPassword">
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用户类型" :label-width="formLabelWidth" prop="isOrg">
              <el-select v-model="form.isOrg" placeholder="请选择">
                <el-option v-for="item in optionsType" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 提交按钮 -->
      <div class="formButton">
        <el-button type="primary" @click="submitForm('form')">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 当前数据id
      id: '',
      optionsType: [{
        value: 0,
        label: '普通用户'
      }, {
        value: 1,
        label: '培训机构'
      }],
      // 表单信息
      form: {},
      // 表单信息验证项
      rules: {
        // varietyName: [
        //   { required: true, message: '请输入xxx', trigger: 'blur' },
        // ],
      },
      //选择角色
      options: [],
      value: '',
      // 左侧标题宽度
      formLabelWidth: '120px',
      //左侧标题对齐
      labelPosition: 'left'
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getDetails()
    this.getList()
    this.getRoleByUserId()
  },
  methods: {
    // 先获取所有角色
    async getList() {
      const { data: res } = await this.$http.get('/admin/sysRole/getPage')
      var data = [];
      res.data.rows.map((value, index, arry) => {
        data.push({ label: value.roleName, value: value.sysRoleId });
      });
      this.options = data
    },
    //获取基本信息
    async getDetails() {
      const { data: res } = await this.$http.get("/admin/sysUser/detail", {
        params: {
          sysUserId: this.id
        }
      })
      this.form = res.data
    },
    // 再获取当前用户角色
    async getRoleByUserId() {
      const { data: res } = await this.$http.get('/admin/sysUser/getRoleByUserId?userId=' + this.id)
      this.value = res.data.sysRoleId
    },
    //提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.putChange()
          this.addRoleToSysUser()
        }
      });
    },
    async putChange() {
      const { data: res } = await this.$http.post("/admin/sysUser/edit", this.form)
      if (res.code != 200) return this.$message.error(res.message);
    },
    //选择角色
    async addRoleToSysUser() {
      const { data: res } = await this.$http.get("/admin/sysUser/addRoleToSysUser?userId=" + this.id + '&roleId=' + this.value)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000);
    }
  }
}
</script>
<style scoped lang="less"></style>