<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path:  '/u_training_list?id='+id }">培训列表</el-breadcrumb-item>
            <el-breadcrumb-item>修改培训</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">修改培训信息</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <div class="formWidth">
        <el-form :model="form" :rules="rules" ref="form" :label-position="labelPosition" :hide-required-asterisk="true">
          <div class="grid-content">
            <el-form-item label="培训名称" :label-width="formLabelWidth" prop="trainingName">
              <el-input v-model="form.trainingName" autocomplete="off" placeholder="请输入培训名称"></el-input>
            </el-form-item>
            <el-form-item label="培训类型" :label-width="formLabelWidth" prop="trainingType">
              <el-input v-model="form.trainingType" autocomplete="off" placeholder="请输入培训类型"></el-input>
            </el-form-item>
            <el-form-item label="培训地址" :label-width="formLabelWidth" prop="trainingAddress">
              <el-cascader size="large" :options="options" v-model="form.trainingAddress" @change="handleChange" :placeholder="form.trainingAddress"></el-cascader>
            </el-form-item>
            <el-form-item label="培训环境" :label-width="formLabelWidth" prop="isOnline">
              <el-select v-model="form.isOnline" placeholder="请选择培训环境" @change="change">
                <el-option v-for="item in optionsType" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="培训计划人数" :label-width="formLabelWidth" prop="trainingPlanNumber">
              <el-input v-model="form.trainingPlanNumber" autocomplete="off" type="number" placeholder="请输入培训计划人数"></el-input>
            </el-form-item>
            <el-form-item label="培训单位" :label-width="formLabelWidth" prop="organizationName">
              <el-input v-model="form.organizationName" autocomplete="off" placeholder="请输入培训单位"></el-input>
            </el-form-item>
            <el-form-item label="培训时间" :label-width="formLabelWidth" prop="trainingTime">
              <el-date-picker v-model="form.trainingTime" type="datetime" placeholder="请输入培训时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 提交按钮 -->
      <div class="formButton">
        <el-button type="primary" @click="submitForm('form')">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data"
export default {
  data() {
    return {
      id: '',
      // 表单信息
      form: {},
      // 表单信息验证项
      rules: {
        trainingName: [
          { required: true, message: '请输入培训名称', trigger: 'blur' },
        ],
        trainingType: [
          { required: true, message: '请输入培训类型', trigger: 'blur' },
        ],
        trainingAddress: [
          { required: true, message: '请输入培训地址', trigger: 'blur' },
        ],
        trainingTime: [
          { required: true, message: '请输入培训时间', trigger: 'blur' },
        ],
        isOnline: [
          { required: true, message: '请输入培训环境', trigger: 'blur' },
        ],
        trainingPlanNumber: [
          { required: true, message: '请输入培训计划人数', trigger: 'blur' },
        ],
        organizationName: [
          { required: true, message: '请输入培训单位', trigger: 'blur' },
        ],
      },
      // 左侧标题宽度
      formLabelWidth: '120px',
      //左侧标题对齐
      labelPosition: 'left',
      // 所在地区
      options: regionData,
      //环境选择
      optionsType: [{
        value: '0',
        label: '线上'
      }, {
        value: '1',
        label: '线下'
      }],
    }
  },
  created() {
    this.id = this.$route.query.id
    this.detail()
  },
  methods: {
    //获取培训详情
    async detail() {
      const { data: res } = await this.$http.get("/admin/training/detail", {
        params: {
          trainingId: this.id
        }
      })
      this.form = res.data
    },
    //选择区域
    handleChange() {
      var loc = "";
      for (let i = 0; i < this.form.trainingAddress.length; i++) {
        loc += CodeToText[this.form.trainingAddress[i]];
      }
      this.form.trainingAddress = loc
    },
    //选择环境
    change(e) {
      this.form.isOnline = e
    },
    //提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.putChange()
        }
      });
    },
    async putChange() {
      const { data: res } = await this.$http.post("/admin/training/edit", this.form)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000);
    },
  }
}
</script>
<style scoped lang="less"></style>