<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>推送列表</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">推送列表</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <div class="wrap">
      <el-row class="operation" :gutter="20">
        <el-col :span="4">
          <el-select v-model="labelValue" placeholder="标签类型" @change="changeLabel">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-table :data="List" border height="520">
        <el-table-column prop="title" label="推送信息">
        </el-table-column>
        <el-table-column prop="userName" label="信息发布者">
        </el-table-column>
        <el-table-column prop="toUserWx" label="推送目标">
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNo" background :page-size="pageSize" layout="total, prev, pager, next" :total="total" style="text-align: right;">
      </el-pagination>
    </div>
  </div>
</template>
  
  
  
  <script>
export default {
  data() {
    return {
      // 分页
      pageNo: 1,
      //数量
      pageSize: 50,
      // 总条数
      total: 0,
      // 列表
      List: [],
      options: [{
        value: '0',
        label: '用户'
      }, {
        value: '1',
        label: '服务'
      }, {
        value: '2',
        label: '任务'
      }, {
        value: '3',
        label: '问题'
      }, {
        value: '4',
        label: '供应'
      }, {
        value: '5',
        label: '采购'
      }],
      labelValue: '0'
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 获取某些类型列表
    async getList() {
      const { data: res } = await this.$http.get('/admin/message/getMessageHistory', {
        params: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          type: this.labelValue,
        }
      })
      this.List = res.data.rows
      this.total = res.data.totalRows
    },
    //下拉框选择
    changeLabel(i) {
      this.getList()
    },
  }
}
</script>
<style scoped lang="scss"></style>