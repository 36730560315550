<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{path:'/role_list'}">角色列表</el-breadcrumb-item>
            <el-breadcrumb-item>角色编辑</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">编辑角色信息</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <div class="formWidth">
        <el-form :model="form" :rules="rules" ref="form" :label-position="labelPosition" :hide-required-asterisk="true">
          <div class="grid-content">
            <el-form-item label="角色编码" :label-width="formLabelWidth" prop="roleCode">
              <el-input v-model="form.roleCode" autocomplete="off" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="角色名" :label-width="formLabelWidth" prop="roleName">
              <el-input v-model="form.roleName" autocomplete="off" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="角色描述" :label-width="formLabelWidth" prop="roleDescription">
              <el-input v-model="form.roleDescription" autocomplete="off" placeholder=""></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 提交按钮 -->
      <div class="formButton">
        <el-button type="primary" @click="submitForm('form')">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 当前数据id
      id: '',
      // 表单信息
      form: {},
      // 表单信息验证项
      rules: {
        // varietyName: [
        //   { required: true, message: '请输入xxx', trigger: 'blur' },
        // ],
      },
      // 左侧标题宽度
      formLabelWidth: '120px',
      //左侧标题对齐
      labelPosition: 'left'
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getDetails()
  },
  methods: {
    //获取
    async getDetails() {
      const { data: res } = await this.$http.get("/admin/sysRole/detail", {
        params: {
          sysRoleId: this.id
        }
      })
      this.form = res.data
    },
    //提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.putChange()
        }
      });
    },
    async putChange() {
      const { data: res } = await this.$http.post("/admin/sysRole/edit", this.form)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000);
    },
  }
}
</script>
<style scoped lang="less"></style>