<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>培训列表</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">培训列表</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <div class="wrap">
      <el-row class="operation" :gutter="20">
        <el-col :span="4">
          <el-input v-model="name" placeholder="搜索培训" @input="change"></el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="tebleAdd">添加培训</el-button>
        </el-col>
      </el-row>
      <el-table :data="List" border height="520">
        <el-table-column prop="trainingName" label="培训名称">
        </el-table-column>
        <el-table-column prop="trainingType" label="培训类型">
        </el-table-column>
        <el-table-column prop="trainingAddress" label="培训地址">
        </el-table-column>
        <el-table-column prop="trainingTime" label="培训时间">
        </el-table-column>
        <el-table-column prop="isOnline" label="培训环境">
          <template slot-scope="scope">
            <span v-if="scope.row.isOnline==0">线上</span>
            <span v-else-if="scope.row.isOnline==1">线下</span>
          </template>
        </el-table-column>
        <el-table-column prop="trainingPlanNumber" label="培训计划人数">
        </el-table-column>
        <el-table-column prop="organizationName" label="培训单位">
        </el-table-column>
        <!-- <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-link :underline="false" type="warning" v-if="scope.row.status==null">待官方审核</el-link>
            <el-link :underline="false" type="success" v-else-if="scope.row.status==1">已通过</el-link>
            <el-tooltip class="item" effect="dark" :content="scope.row.reason" placement="top-start" type="danger" v-else-if="scope.row.status==2">
              <el-link :underline="false" type="danger">已驳回</el-link>
            </el-tooltip>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="text" @click="review(scope.row.trainingId)">编辑</el-button>
            <el-button type="text" @click="toUser(scope.row.trainingId)">学员</el-button>
            <el-popconfirm title="确定删除此条数据吗？" @confirm='deletes(scope.row.trainingId)'>
              <el-button type="text" slot="reference" style="margin-left:10px">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNo" background :page-size="pageSize" layout="total, prev, pager, next" :total="total" style="text-align: right;">
      </el-pagination>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      //搜索
      name: '',
      // 分页
      pageNo: 1,
      //数量
      pageSize: 50,
      // 总条数
      total: 0,
      // 列表
      List: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 列表
    async getList() {
      const { data: res } = await this.$http.get('/admin/training/getPageAllByOrganizationId', {
        params: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          trainingName: this.name,
          organizationId: window.sessionStorage.getItem("organizationId")
        }
      })
      this.List = res.data.rows
      this.total = res.data.totalRows
    },
    //删除
    deletes(id) {
      this.remove(id)
    },
    async remove(id) {
      const { data: res } = await this.$http.post('/admin/training/delete', { trainingId: id })
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getList()
    },
    //编辑
    review(id) {
      this.$router.push({
        path: '/u_training_detils',
        query: {
          id: id
        }
      });
    },
    toUser(id) {
      this.$router.push({
        path: '/u_training_user_detils',
        query: {
          id: id
        }
      });
    },
    //添加
    tebleAdd() {
      this.$router.push({
        path: '/u_training_add',
      });
    },
    //搜索
    change(e) {
      this.name = e
      this.getList()
    },
    // 分页-数量
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    // 分页-页码
    handleCurrentChange(val) {
      this.pageNo = val
      this.getList()
    },
  }
}
</script>
<style scoped lang="scss"></style>