<template>
  <div class=''></div>
</template>

<script>
//导入文件
//import  from '';

export default {
  components: {},
  data() { return {} },
  computed: {},
  watch: {},
  methods: {},
  created() { },
  mounted() { },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
}
</script>
<style scoped></style>