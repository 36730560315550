<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/ulist' }">用户列表</el-breadcrumb-item>
            <el-breadcrumb-item>添加用户</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">添加用户信息</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <div class="formWidth">
        <el-form :model="form" :rules="rules" ref="form" :label-position="labelPosition" :hide-required-asterisk="true">
          <div class="grid-content">
            <el-form-item label="用户名" :label-width="formLabelWidth" prop="userName">
              <el-input v-model="form.userName" autocomplete="off" placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item label="用户手机" :label-width="formLabelWidth" prop="userPhone">
              <el-input v-model="form.userPhone" autocomplete="off" placeholder="请输入用户手机"></el-input>
            </el-form-item>
            <el-form-item label="用户地区" :label-width="formLabelWidth" prop="userArea">
              <el-cascader size="large" :options="options" v-model="form.userArea" @change="handleChange" :placeholder="form.userArea"></el-cascader>
            </el-form-item>
            <el-form-item label="用户详细地址" :label-width="formLabelWidth" prop="userAddress">
              <el-input v-model="form.userAddress" autocomplete="off" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="用户简介" :label-width="formLabelWidth" prop="userDesc">
              <el-input v-model="form.userDesc" autocomplete="off" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 提交按钮 -->
      <div class="formButton">
        <el-button type="primary" @click="submitForm('form')">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data"
export default {
  data() {
    return {
      // 当前数据id
      id: '',
      // 表单信息
      form: {
        userName: '',
        userPhone: '',
        userArea: '',
        userAddress: '',
        userDesc: '',
      },
      // 表单信息验证项
      rules: {
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        userPhone: [
          { required: true, message: '请输入用户手机', trigger: 'blur' },
        ],
        userArea: [
          { required: true, message: '请选择用户地区', trigger: 'blur' },
        ],
        userAddress: [
          { required: true, message: '请输入用户详细地址', trigger: 'blur' },
        ],
        userDesc: [
          { required: true, message: '请输入用户简介', trigger: 'blur' },
        ],
      },
      // 左侧标题宽度
      formLabelWidth: '120px',
      //左侧标题对齐
      labelPosition: 'left',
      // 所在地区
      options: regionData
    }
  },
  methods: {
    //选择区域
    handleChange() {
      var loc = "";
      for (let i = 0; i < this.form.userArea.length; i++) {
        loc += CodeToText[this.form.userArea[i]];
      }
      this.form.userArea = loc
    },
    //提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.putChange()
        }
      });
    },
    async putChange() {
      const { data: res } = await this.$http.post("/admin/user/add", this.form)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000);
    }
  }
}
</script>
<style scoped lang="less"></style>