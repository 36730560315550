<template>
  <div>
    <!-- 列表 -->
    <el-table :data="List" border height="520">
      <el-table-column prop="taskTitle" label="任务标题">
      </el-table-column>
      <el-table-column prop="taskContent" label="任务内容">
      </el-table-column>
      <el-table-column prop="taskFee" label="任务价格">
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-link :underline="false" type="warning" v-if="scope.row.status==0">待审核</el-link>
          <el-link :underline="false" type="success" v-else-if="scope.row.status==1">已通过</el-link>
          <el-tooltip class="item" effect="dark" :content="scope.row.reason" placement="top-start" type="danger" v-else-if="scope.row.status==2">
            <el-link :underline="false" type="danger">已驳回</el-link>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="发布时间">
      </el-table-column>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button type="text" @click="review(scope.row.taskId)">查看</el-button>
          <el-popconfirm title="确定删除此条数据吗？" @confirm='deletes(scope.row.taskId)'>
            <el-button type="text" slot="reference" style="margin-left:10px">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNo" background :page-size="pageSize" layout="total, prev, pager, next" :total="total" style="text-align: right;">
    </el-pagination>
  </div>
</template>



<script>
export default {
  data() {
    return {
      id: '',
      // 分页
      pageNo: 1,
      //数量
      pageSize: 50,
      // 总条数
      total: 0,
      // 列表
      List: [],
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getList()
  },
  methods: {
    // 列表
    async getList() {
      const { data: res } = await this.$http.get('/admin/task/getPageByUserId', {
        params: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          userId: this.id,
        }
      })
      this.List = res.data.rows
      this.total = res.data.totalRows
    },
    //删除
    deletes(id) {
      this.remove(id)
    },
    async remove(id) {
      const { data: res } = await this.$http.post('/admin/task/delete', { taskId: id })
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getList()
    },
    //编辑
    review(id) {
      this.$router.push({
        path: '/release_task_details',
        query: {
          id: id
        }
      });
    },
    // 分页-数量
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    // 分页-页码
    handleCurrentChange(val) {
      this.pageNo = val
      this.getList()
    },

  }
}



</script>
<style scoped lang="scss"></style>