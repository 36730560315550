<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>操作日志列表</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">操作日志列表</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <div class="wrap">
      <el-row class="operation" :gutter="20">
        <el-col :span="4">
          <el-input v-model="name" placeholder="搜索系统用户" @input="change"></el-input>
        </el-col>
      </el-row>
      <el-table :data="List" border height="520">
        <el-table-column prop="operateLogName" label="账号">
        </el-table-column>
        <el-table-column prop="operateLogExplain" label="操作说明">
        </el-table-column>
        <el-table-column prop="operateLogController" label="访问控制器">
        </el-table-column>
        <el-table-column prop="operateLogIp" label="IP">
        </el-table-column>
        <el-table-column prop="operateLogArea" label="区域">
        </el-table-column>
        <el-table-column prop="createTime" label="时间">
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNo" background :page-size="pageSize" layout="total, prev, pager, next" :total="total" style="text-align: right;">
      </el-pagination>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      //搜索
      name: '',
      // 分页
      pageNo: 1,
      //数量
      pageSize: 10,
      // 总条数
      total: 0,
      // 列表
      List: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 列表
    async getList() {
      const { data: res } = await this.$http.get('/admin/operateLog/getPage', {
        params: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          operateLogName: this.name
        }
      })
      this.List = res.data.rows
      this.total = res.data.totalRows
    },
    //搜索
    change(e) {
      this.name = e
      this.getList()
    },
    // 分页-数量
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    // 分页-页码
    handleCurrentChange(val) {
      this.pageNo = val
      this.getList()
    },

  }
}



</script>
<style scoped lang="scss"></style>