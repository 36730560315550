<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/ulist' }">教学列表</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/teaching_details?id='+id }">教学详情</el-breadcrumb-item>
            <el-breadcrumb-item>教学编辑</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">编辑教学信息</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <div class="formWidth">
        <el-form :model="form" :rules="rules" ref="form" :label-position="labelPosition" :hide-required-asterisk="true">
          <div class="grid-content">
            <el-form-item label="教学名称" :label-width="formLabelWidth" prop="teachName">
              <el-input v-model="form.teachName" autocomplete="off" placeholder="请输入服务标题"></el-input>
            </el-form-item>
            <el-form-item label="教学类型" :label-width="formLabelWidth" prop="teachType">
              <el-input v-model="form.teachType" autocomplete="off" placeholder="请输入服务内容"></el-input>
            </el-form-item>
            <el-form-item label="教学简介" :label-width="formLabelWidth" prop="teachDesc">
              <el-input v-model="form.teachDesc" autocomplete="off" placeholder="请输入教学简介"></el-input>
            </el-form-item>
            <el-form-item label="教学作者" :label-width="formLabelWidth">
              <el-select v-model="form.userId" placeholder="请选择">
                <el-option v-for="(item,i) in options" :key="i" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="教学封面" :label-width="formLabelWidth" prop="teachHead">
              <div>
                <el-upload :action="action" list-type="picture-card" :on-success="success" :limit="1" :file-list="fileList" :on-remove="remove">
                  <el-button size="small" type="primary">添加图片</el-button>
                </el-upload>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 提交按钮 -->
      <div class="formButton">
        <el-button type="primary" @click="submitForm('form')">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 当前数据id
      id: '',
      // 表单信息
      form: {},
      //图片
      fileList: [],
      // 表单信息验证项
      rules: {
        teachName: [
          { required: true, message: '请输入服务标题', trigger: 'blur' },
        ],
        teachType: [
          { required: true, message: '请输入服务内容', trigger: 'blur' },
        ],
        teachHead: [
          { required: true, message: '请上传教学封面', trigger: 'blur' },
        ],
        teachDesc: [
          { required: true, message: '请输入教学简介', trigger: 'blur' },
        ],
      },
      options: [],
      // 左侧标题宽度
      formLabelWidth: '120px',
      //左侧标题对齐
      labelPosition: 'left',
    }
  },
  created() {
    this.id = this.$route.query.id
    this.action = this.$http.defaults.baseURL + 'file/upLoadImage'
    this.getDetails()
    this.getDetailsLable()
  },
  methods: {
    //获取
    async getDetailsLable() {
      const { data: res } = await this.$http.get("/admin/user/auth/getPageExperts")
      let data = [];
      res.data.rows.map((value, index, arry) => {
        data.push({ label: value.userName, value: value.userId });
      });
      this.options = data
    },
    //获取
    async getDetails() {
      const { data: res } = await this.$http.get("/admin/teach/detail", {
        params: {
          teachId: this.id
        }
      })
      this.form = res.data
      let iut = res.data.teachHead.split()
      let data = [];
      iut.map((value, index, arry) => {
        data.push({ url: value });
      });
      this.fileList = data
    },
    //提交
    submitForm(formName) {
      let dataImg = []
      this.fileList.map((value, index, arry) => {
        dataImg.push(value.url);
      });
      this.form.teachHead = dataImg.toString()
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.putChange()
        }
      });
    },
    async putChange() {
      const { data: res } = await this.$http.post("/admin/teach/edit", this.form)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000);
    },
    //图片上传成功后
    success(response, file, fileList) {
      this.fileList.push({ url: response.data })
    },
    //移除图片
    remove(file, fileList) {
      this.fileList = []
      fileList.forEach(file => {
        this.fileList.push({ url: file.url });
      });
    },
    //简介

  }
}
</script>
<style scoped lang="less"></style>