<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item>权限列表</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">权限列表</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <div class="wrap">
      <el-row class="operation" :gutter="20">
        <el-col :span="4">
          <el-input v-model="name" placeholder="搜索权限" @input="change"></el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="tebleAdd">添加权限</el-button>
        </el-col>
      </el-row>
      <el-table :data="List" border height="520">
        <el-table-column prop="permissionCode" label="权限编码">
        </el-table-column>
        <el-table-column prop="permissionName" label="权限名称">
        </el-table-column>
        <el-table-column prop="url" label="路径">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间">
        </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="text" @click="review(scope.row.sysPermissionId)">编辑</el-button>
            <el-popconfirm title="确定删除此条数据吗？" @confirm='deletes(scope.row.sysPermissionId)'>
              <el-button type="text" slot="reference" style="margin-left:10px">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNo" background :page-size="pageSize" layout="total, prev, pager, next" :total="total" style="text-align: right;">
      </el-pagination>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      //搜索
      name: '',
      // 分页
      pageNo: 1,
      //数量
      pageSize: 10,
      // 总条数
      total: 0,
      // 列表
      List: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 列表
    async getList() {
      const { data: res } = await this.$http.get('/admin/sysPermission/getPage', {
        params: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          permissionName: this.name,
        }
      })
      this.List = res.data.rows
      this.total = res.data.totalRows
    },
    //删除
    deletes(id) {
      this.remove(id)
    },
    async remove(id) {
      const { data: res } = await this.$http.post('/admin/sysPermission/delete', { sysPermissionId: id })
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      this.getList()
    },
    //编辑
    review(id) {
      this.$router.push({
        path: '/permission_details',
        query: {
          id: id
        }
      });
    },
    //添加
    tebleAdd() {
      this.$router.push({
        path: '/permission_add',
      });
    },
    //搜索
    change(e) {
      this.name = e
      this.getList()
    },
    // 分页-数量
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    // 分页-页码
    handleCurrentChange(val) {
      this.pageNo = val
      this.getList()
    },

  }
}



</script>
<style scoped lang="scss"></style>