<template>
  <div>
    <!-- 面包屑 -->
    <el-row>
      <el-col :span="24">
        <el-card class="box-card" shadow="never">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{path:'/role_list'}">角色列表</el-breadcrumb-item>
            <el-breadcrumb-item>角色权限</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="title">编辑角色权限</div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <div class="wrap">
      <div class="formWidth">
        <el-form :label-position="labelPosition">
          <div class="grid-content">
            <el-form-item label="选择权限" :label-width="formLabelWidth">
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
              <div style="margin: 15px 0;"></div>
              <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                <el-checkbox v-for="city in cities" :label="city.value" :key="city.value">{{city.label}}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- 提交按钮 -->
      <div class="formButton">
        <el-button type="primary" @click="submitForm('form')">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 当前数据id
      id: '',
      //权限选择
      checkAll: false,
      checkedCities: [],
      cities: [],
      citiesList: [],
      isIndeterminate: true,
      // 左侧标题宽度
      formLabelWidth: '120px',
      //左侧标题对齐
      labelPosition: 'left'
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getList()
    this.getPermissionsOfRole()
  },
  methods: {
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.citiesList : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
      this.permissionIds = value
    },
    // 先获取所有权限
    async getList() {
      const { data: res } = await this.$http.get('/admin/sysPermission/getPage')
      var data = [];
      var datas = [];
      res.data.rows.map((value, index, arry) => {
        data.push({ label: value.permissionName + value.url, value: value.sysPermissionId });
        datas.push(value.sysPermissionId)
      });
      this.cities = data
      this.citiesList = datas
    },
    // 再获取当前角色已有权限
    async getPermissionsOfRole() {
      const { data: res } = await this.$http.get('/admin/sysUser/getPermissionsOfRole', {
        params: {
          roleId: this.id
        }
      })
      var data2 = [];
      res.data.map((value, index, arry) => {
        data2.push(value.sysPermissionId);
      });
      this.checkedCities = data2
    },
    //提交
    submitForm() {
      this.sysPermission()
    },
    async sysPermission() {
      const { data: res } = await this.$http.post("/admin/sysUser/addPermissionsToRole?roleId=" + this.id + '&permissionIds=' + this.checkedCities)
      if (res.code != 200) return this.$message.error(res.message);
      this.$message.success(res.message);
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000);
    },
  }
}
</script>
<style scoped lang="less"></style>